import React from "react";
import { Row, Col, Button } from "antd";
//import "./Sections/Navbar.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { USER_SERVER } from "../../../config";
import { removeCookie } from "../../../cookie/cookie";
import axios from "axios";
import NavImage from "../../../assets/images/nav.png";
import HelpImage from "../../../assets/images/help.png";

function NavBar() {
    const user = useSelector((state) => state.user);
    const UserInfo = user?.userData;     // UserInfo={token, user_id}
    const isAuth = UserInfo?.isAuth;

    async function logoutHandler(){
        const response = await axios.get(`${USER_SERVER}/web/logoutgoogle`);
        if (response.status === 200) {
            removeCookie('w_auth');
            //history.push("/login");
            console.log('logout successfully');
            window.location.reload(); // 페이지 새로고침
        } else {
            Swal.fire({ icon: "error", title: ("Oops..."), text: ("Something went wrong, Logout Failed") });
        }
    };

    async function loginHandler(){
        window.location.href = "/login";
    };

    async function helpHandler(){
        Swal.fire({
            imageUrl: HelpImage,
            imageWidth: 350,
            imageHeight: 400,
            imageAlt: 'Help Image',
            title: 'Help Information',
            showConfirmButton: false,
            showCloseButton: true
        });
    };

    const login_button = isAuth ? 
        <Button onClick={logoutHandler} style={{fontWeight: 'bold', fontSize: '16px', backgroundColor: '#D3D3D3', marginRight: '10px'}}>Logout</Button> : 
        <Button onClick={loginHandler} style={{fontWeight: 'bold', fontSize: '16px', backgroundColor: '#D3D3D3', marginRight: '10px'}}>Login</Button>;

    return (

        <nav style={{ backgroundColor: 'rgb(22,28,34)'}}>
            <Row justify="start" align="middle" style={{height: '110px'}}>
                <Col span={22}>
                    <img src={NavImage} alt="NavImage" style={{height: '80px' }} />
                </Col>
                <Col span={2}>
                    <Row justify="end">
                        <Button onClick={helpHandler} style={{fontWeight: 'bold', fontSize: '16px', backgroundColor: '#D3D3D3', marginRight: '10px'}}>Help  </Button>
                    </Row>
                    <Row justify="end" style={{marginTop: '10px'}}>
                        {login_button}
                    </Row>
                </Col>
            </Row>
        </nav>
    );
}

export default NavBar;
