import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth from "../hoc/auth";

import LoginPage from "../components/views/02_LoginPage/LoginPage";
import LandingPage from '../components/views/10_LandingPage/LandingPage';
//import LandingScenario from "../components/views/10_LandingPage/LandingScenario";
//import LandingDev from '../components/views/10_LandingPage/LandingDev';

import Page404 from "../components/views/ErrorPages/Page404";

const Routes = () => {

    return (
        <Switch>
            {/* null-->누구나 접근가능, true-->로그인한 사용자만 접근가능, false-->로그인 안한 사용자만 접근가능 */}
			<Route exact path="/" component={Auth(LandingPage, null)} />
            <Route exact path="/login" component={Auth(LoginPage, null)} />
			{/* <Route exact path="/scenario" component={Auth(LandingScenario, true)} />
			<Route exact path="/develope" component={Auth(LandingDev, true)} /> */}
            <Route component={Page404} exact />
        </Switch>
    );
};

export default Routes;