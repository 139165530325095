import {withRouter} from "react-router-dom";
import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Button, Input, Spin, Tooltip} from 'antd';

function Buttons({
                     onClickUploadImage,
                     onClickSend,
                     onClickRecStart,
                     onClickRecStop,
                     onClickReset,
                     onClickCustom1,
                     onClickCustom2,
                     RecStatus,
                     WaitAnswer
                 }) {
    const [MicMode, setMicMode] = useState(false);
    const [TooltipOpen, setTooltipOpen] = useState(false);

    async function onClickRec() {
        toggleTooltip();
        console.log('onClickRec');
        if (MicMode) {
            await onClickRecStop();
        } else {
            await onClickRecStart();
        }

        setMicMode(!MicMode);
    }

    const mic_text = (MicMode) ? '停' : '启';
    const button_color = (RecStatus === 'rec') ? 'red' : 'blue';
    const mic_btn_disable = (RecStatus === 'prepare_rec' || WaitAnswer);

    function toggleTooltip() {
        setTooltipOpen(!TooltipOpen);
    };

    const images = []


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
        }}>
            {/* Upload image */}
            <div
                style={{
                    position: "relative",
                    borderRadius: "20%",
                }}
            >
                {
                    WaitAnswer &&
                    <div style={{
                        position: "absolute",
                        backgroundColor: "lightgray",
                        opacity: "0.7",
                        borderRadius: "20%",
                        width: "100%",
                        height: "100%",
                    }}/>
                }
                <div
                    style={{
                        backgroundColor: "black",
                        borderRadius: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                    onClick={onClickUploadImage}
                >
                    <img src={"/images/picture.svg"}/>
                </div>
            </div>

            {/* Send Text */}
            <div
                style={{
                    position: "relative",
                }}
            >
                {
                    WaitAnswer &&
                    <div style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "lightgray",
                        opacity: "0.7",
                        borderRadius: "20%",
                        width: "100%",
                        height: "100%",
                    }}>
                        <Spin size={"large"}/>
                    </div>
                }
                <div
                    style={{
                        borderRadius: "20%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                    onClick={onClickSend}
                >
                    <img src={"/images/text.svg"}/>
                </div>
            </div>

            {/* Mic */}
            <div
                style={{
                    position: "relative",
                }}
            >
                {
                    mic_btn_disable &&
                    <div style={{
                        position: "absolute",
                        backgroundColor: "lightgray",
                        opacity: "0.7",
                        borderRadius: "20%",
                        width: "100%",
                        height: "100%",
                    }}/>
                }
                <div
                    style={{
                        borderRadius: "20%",
                        backgroundColor: button_color === "blue" ? "black" : "red",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                    onClick={onClickRec}
                >
                    <img src={"/images/mic.svg"}/>
                </div>
            </div>

            {/* Refresh */}
            <div
                style={{
                    position: "relative",
                }}
            >
                {
                    WaitAnswer &&
                    <div style={{
                        position: "absolute",
                        backgroundColor: "lightgray",
                        opacity: "0.7",
                        borderRadius: "20%",
                        width: "100%",
                        height: "100%",
                    }}/>
                }
                <div
                    style={{
                        borderRadius: "20%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                    onClick={onClickReset}
                >
                    <img src={"/images/refresh.svg"}/>
                </div>
            </div>

            {/* Plane */}
            <div
                style={{
                    position: "relative",
                }}
            >
                {
                    WaitAnswer &&
                    <div style={{
                        position: "absolute",
                        backgroundColor: "lightgray",
                        opacity: "0.7",
                        borderRadius: "20%",
                        width: "100%",
                        height: "100%",
                    }}/>
                }
                <div
                    style={{
                        borderRadius: "20%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                    onClick={onClickCustom1}
                >
                    <img src={"/images/plane.svg"}/>
                </div>
            </div>

            {/* Explain */}
            <div
                style={{
                    position: "relative",
                }}
            >
                {
                    WaitAnswer &&
                    <div style={{
                        position: "absolute",
                        backgroundColor: "lightgray",
                        opacity: "0.7",
                        borderRadius: "20%",
                        width: "100%",
                        height: "100%",
                    }}/>
                }
                <div
                    style={{
                        borderRadius: "20%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                    onClick={onClickCustom2}
                >
                    <img src={"/images/explain.svg"}/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Buttons)
