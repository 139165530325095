import React, {useState, useEffect, useRef} from 'react';
import {withRouter} from "react-router-dom";
import {Row, Col, Input} from 'antd';
import {useSelector} from "react-redux";
import Chat from './Chat';
import MapImage from "../../../assets/images/newmap1.png";
import Buttons from './Buttons';

function LandingPage(props) {
    const chatRef = useRef(null);

    let user = useSelector((state) => state.user);
    const UserInfo = user.userData;     // UserInfo={token, user_id}
    const isAuth = UserInfo?.isAuth;

    const [RecStatus, setRecStatus] = useState('ready');
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const imageRef = useRef();
    const [ImgUrl, setImgUrl] = useState("");
    const [ImgUrlToSend, setImgUrlToSend] = useState("");
    const [Img, setImg] = useState();

    const [chat_height, setChatHeight] = useState(window.innerHeight - 115);

    window.addEventListener('resize', () => {
        console.log("Updated viewport height:", window.innerHeight);
        setChatHeight(window.innerHeight - 112);
    });

    useEffect(() => {
        console.log('ImgUrl=', ImgUrl);
    }, [ImgUrl]);

    function loginHandler(){
        window.location.href = "/login";
    }

    const handleButtonSend = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        if (chatRef.current) {
            await chatRef.current.onClickSend();
            setImg(null);
        }
    }

    const handleRecStart = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        if (chatRef.current) {
            await chatRef.current.onClickRecStart();
        }
    }

    const handleRecStop = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        if (chatRef.current) {
            await chatRef.current.onClickRecStop();
        }
    }

    const handleReset = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        if (imageRef.current) {
            setImgUrl("");
            setImgUrlToSend("");
            setImg(null);
            URL.revokeObjectURL(ImgUrl);
            URL.revokeObjectURL(ImgUrlToSend);
        }
        if (chatRef.current) {
            await chatRef.current.onClickReset();
        }
    }

    const handleCustom1 = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        if (chatRef.current) {
            await chatRef.current.onClickCustom1();
        }
    }

    const handleCustom2 = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        if (chatRef.current) {
            await chatRef.current.onClickCustom2();
        }
    }

    const handleUploadImage = async () => {
        if (!isAuth) {
            loginHandler();
            return;
        }
        console.log("handleUploadImage");
        if (imageRef.current) {
            console.log("imageRef.current.click()");
            imageRef.current.click()
        }
    }

    return (
        <div>
            <Row style={{alignItems: "center", justifyContent: "center"}}>
                <Col span={11}>
                    {ImgUrl && (
                        <img
                            src={ImgUrl}
                            alt="Map"
                            style={{
                                width: '100%',
                                height: `${chat_height}px`,
                                display: 'block',
                                objectFit: 'cover'
                            }}
                        />
                    )}
                    {!ImgUrl && (
                        <img
                            src={MapImage}
                            alt="Map"
                            style={{
                                width: '100%',
                                height: `${chat_height}px`,
                                display: 'block',
                                objectFit: 'cover'
                            }}
                        />
                    )}
                </Col>
                <Col span={11} style={{backgroundColor: 'rgb(33,33,33)', color: 'white', height: `${chat_height}px`}}>
                    <Chat
                        ref={chatRef}
                        UserInfo={UserInfo}
                        RecStatus={RecStatus}
                        setRecStatus={setRecStatus}
                        WaitAnswer={WaitAnswer}
                        setWaitAnswer={setWaitAnswer}
                        Img={Img}
                        chat_height={chat_height}
                    />
                </Col>
                <Col span={2} style={{backgroundColor: '#141c21', color: 'white', height: `${chat_height}px`}}>
                    <Buttons
                        onClickSend={handleButtonSend}
                        onClickUploadImage={handleUploadImage}
                        onClickRecStart={handleRecStart}
                        onClickRecStop={handleRecStop}
                        onClickReset={handleReset}
                        onClickCustom1={handleCustom1}
                        onClickCustom2={handleCustom2}
                        RecStatus={RecStatus}
                        WaitAnswer={WaitAnswer}
                    />
                </Col>

            </Row>

            <input
                ref={imageRef}
                type={"file"}
                multiple={false}
                hidden={true}
                accept={"image/jpeg,image/jpg,image/png,image/webp"}
                onChange={async function (event) {
                    console.log("imageRef.current.onChange");
                    URL.revokeObjectURL(Img);
                    setImg();
                    setImgUrl("");
                    setImgUrlToSend("");
                    if (event.target.files.length > 0) {
                        setImg(event.target.files[0]);
                        setImgUrl(URL.createObjectURL(event.target.files[0]));
                        setImgUrlToSend(URL.createObjectURL(event.target.files[0]));
                    }
                    event.target.value = null;
                }}
            />

        </div>
    )
}

export default withRouter(LandingPage)
